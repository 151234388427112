const getToken = require('./getToken');
const urljoin = require('url-join');

function getParams() {
    if (window.vizia_telemetry) {
        return new Promise(resolve => {
            try {
                const telemetryInfo = {
                    session: window.vizia_telemetry.rawSession,
                    descriptor: window.vizia_telemetry.rawDescriptor
                };
                resolve(telemetryInfo);
            } catch (e) {
                window.vizia_telemetry.on('ready', () => getParams().then(resolve));
                setTimeout(() => resolve({}), 100);
            }
        });
    }

    return Promise.resolve({});
}

module.exports = (bundle, config) => {
    if (config.scene && ['ready', 'always'].includes(config.scene.serverside) && bundle.useService) {
        return getParams().then(() => {
            const typeId = config.remoteBundleId || config.scene.type;

            if (config.is_public) {
                console.error('bundle-data-proxy has been decomissioned.');
                return Promise.resolve();
            }

            return getToken().then(() => {
                if (config.scene && config.scene.id && config.scene.id !== 'draft') {
                    console.error('bundle-data-proxy has been decomissioned.');
                } else {
                    const dataURL = urljoin(window.CONFIG.cloudBundlesOrigin, typeId);
                    if (bundle.useService) {
                        bundle.useService(dataURL);
                    }
                }
            });
        });
    }
    return Promise.resolve();
};
