'use strict';

const {round} = require('./math');
const formatKey = require('./format-key');
const {CSS_ROOT_SCALE} = require('./constants');

/**
 * Gets clamped value between min and max
 */
const getClampedValue = (value, min = 0, max = 1) => Math.max(min, Math.min(value, max));

/**
 * Gets circular value if value is out of bounds
 */
const getCircularValue = (value, min = 0, max = 1) => {
    const remainder = value % max;
    return (remainder < min ? max : 0) + remainder;
};

/**
 * Each processor is a factory that takes a definition node and exports a hash of processor functions.
 * Processor functions return an array of tuples, so that a processor can transform one definition node into multiple tokens
 */
const tokenProcessors = {
    default: () => ({node, path, key, isAlias}) => ({
        json: () => [
            {
                key: formatKey([...path, key], 'json'),
                value: node.value,
                format: null,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ],
        css: () => [
            {
                key: formatKey([...path, key], 'css'),
                value: node.value,
                format: node.format,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ]
    }),
    measure: ({zoom}) => ({node, path, key, isAlias}) => ({
        json: () => [
            {
                key: formatKey([...path, key], 'json'),
                value: round(node.value * zoom),
                format: node.format,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ],
        css: () => [
            {
                key: formatKey([...path, key], 'css'),
                value: `${round((node.value / 10) * (10 / CSS_ROOT_SCALE) * zoom)}rem`,
                format: node.format,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ]
    }),
    em: () => ({node, path, key, isAlias}) => ({
        json: () => [
            {
                key: formatKey([...path, key], 'json'),
                value: round(node.value),
                format: node.format,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ],
        css: () => [
            {
                key: formatKey([...path, key], 'css'),
                value: `${round(node.value)}em`,
                format: node.format,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ]
    }),
    color: () => ({node, path, key, isAlias}) => {
        const {h, s, l} = node.value;
        return {
            json: () => [
                {
                    key: formatKey([...path, key], 'json'),
                    value: [round(h, 0), round(s, 2), round(l, 2)],
                    format: node.format,
                    description: node.description,
                    isAlias,
                    path: [...path, key]
                }
            ],
            css: () => [
                {
                    key: formatKey([...path, key], 'css'),
                    value: `hsl(${round(h, 0)}, ${round(s * 100, 0)}%, ${round(l * 100, 0)}%)`,
                    format: node.format,
                    description: node.description,
                    isAlias,
                    path: [...path, key]
                },
                {
                    key: formatKey([...path, key, 'hsl'], 'css'),
                    value: `${round(h, 0)}, ${round(s * 100, 0)}%, ${round(l * 100, 0)}%`,
                    format: node.format,
                    description: `${node.description} Spreadable.`,
                    isAlias,
                    path: [...path, key, 'hsl']
                }
            ]
        };
    },
    'color-shift': (props) => ({node, path, key, parentNode, isAlias}) => {
        const {h: hShift = 0, s: sShift = 0, l: lShift = 0} = node.value;
        const {h: hBase, s: sBase, l: lBase} = parentNode.value;
        const h = getCircularValue(hBase + hShift, 0, 360);
        const s = getClampedValue(sBase + sShift);
        const l = getClampedValue(lBase + lShift);
        const processedNode = Object.assign({}, node, {
            value: {h, s, l}
        });

        return tokenProcessors.color(props)({node: processedNode, path, key, parentNode, isAlias});
    },
    list: () => ({node, path, key, isAlias}) => ({
        json: () => [
            {
                key: formatKey([...path, key], 'json'),
                value: node.value,
                format: node.format,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ],
        css: () => [
            {
                key: formatKey([...path, key], 'css'),
                value: node.value.join(', '),
                format: node.format,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ]
    }),
    'font-list': () => ({node, path, key, isAlias}) => ({
        json: () => [
            {
                key: formatKey([...path, key], 'json'),
                value: node.value,
                format: node.format,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ],
        css: () => [
            {
                key: formatKey([...path, key], 'css'),
                value: node.value.map((value) => `'${value}'`).join(', '),
                format: node.format,
                description: node.description,
                isAlias,
                path: [...path, key]
            }
        ]
    }),
    ratio: () => ({node, path, key, isAlias}) => {
        const [a, b] = node.value;
        return {
            json: () => [
                {
                    key: formatKey([...path, key], 'json'),
                    value: [a, b],
                    format: node.format,
                    description: node.description,
                    isAlias,
                    path: [...path, key]
                }
            ],
            css: () => [
                {
                    key: formatKey([...path, key], 'css'),
                    value: `calc(${a} / ${b})`,
                    format: node.format,
                    description: node.description,
                    isAlias,
                    path: [...path, key]
                }
            ]
        };
    }
};

/**
 * Token processor factory
 */
const createTokenProcessor = (props) => ({
    node,
    path,
    key,
    parentNode,
    isAlias = false
}) => {
    const processor = tokenProcessors[node.format] ? tokenProcessors[node.format] : tokenProcessors.default;

    return processor(props)({
        node,
        path,
        key,
        parentNode,
        isAlias
    });
};

module.exports = createTokenProcessor;
