

const retryingFetcher = require('./retryingFetcher');
const clientSideConfig = require('../conf/clientSideConfig');

exports.manifest = function (bundleName, idToken, slide) {
    const manifestUrl = `${clientSideConfig().bundleRootUrl + bundleName}/manifest.json`;
    return retryingFetcher.fetch(manifestUrl, idToken, slide).then(manifest => manifest);
};

exports.bundle = function (url, idToken) {
    const fullProxyUrl = clientSideConfig().proxyRootUrl + encodeURIComponent(url);
    return retryingFetcher.fetch(url, idToken).then(null, () => {
        return retryingFetcher.fetch(fullProxyUrl, idToken);
    });
};
