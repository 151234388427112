'use strict';

const cache = require('@vizia/local-cache');

function tokenKey(key){
    return `token_${key}`;
}

function machineIdKey(key){
    return `machineId_${key}`;
}

class TokenCache {
    constructor(storageLayer) {
        this._cache = cache.create(storageLayer);
    }

    getToken(key) {
        return this._cache.getItem(tokenKey(key));
    }

    setToken(key, token, ttl) {
        this._cache.setItem(tokenKey(key), token, ttl);
    }

    getMachineId(key) {
        return this._cache.getItem(machineIdKey(key));
    }

    setMachineId(key, machineId) {
        this._cache.setItem(machineIdKey(key), machineId);
    }
}

exports.create = (storageLayer) => new TokenCache(storageLayer);
