'use strict';

const chroma = require('chroma-js');

const hslToHex = ({h, s, l}) => chroma.hsl(h, s, l).hex();

const hexToHsl = (hex) => {
    const [h, s, l] = chroma.hex(hex).hsl();
    return {h: h || 0, s, l};
};

module.exports = {hslToHex, hexToHsl};
