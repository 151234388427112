module.exports = (config) => {
    let h;
    const s = JSON.stringify(config);
    for (let i = 0; i < s.length; i++) {
        // eslint-disable-next-line no-bitwise
        h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    }

    return h;
};
