'use strict';

const flatten = require('./flatten');
const createTokenProcessor = require('./create-token-processor');

/**
 * Traverses a tree of definition nodes to return a flat array of processor functions
 */
const getProcessors = (node, props) => {
    const traverse = (nodeRef, path = [], parentNode, isDerivedFromAlias) => {
        const node = typeof nodeRef === 'function' ? nodeRef(props) : nodeRef;
        const {
            key,
            alias = [],
            children = []
        } = node;
        const keys = [key, ...(Array.isArray(alias) ? alias : [alias])];

        return keys.map((key) => {
            const isAlias = isDerivedFromAlias || alias.includes(key);

            return [
                createTokenProcessor(props)({
                    node,
                    path,
                    key,
                    parentNode,
                    isAlias
                }),
                ...children.map((child) => traverse(child, [...path, key], node, isAlias))
            ];
        });
    };

    return []
        .concat(traverse(node))
        // Flatten processors to one array
        .reduce(flatten.recursive, []);
};

/**
 * Run all processors and return a tokens collection
 */
const reduceProcessors = (processors, output) => processors
    // Invoke processor
    .map((processor) => processor[output]())
    // Flatten output tokens to one collection
    .reduce(flatten, [])
    // Reject absent values
    .filter(({value}) => value !== null && value !== undefined);

/**
 * Get tokens from a definition node
 */
const getTokens = (node, props) => {
    const processors = getProcessors(node, props);

    return {
        json: reduceProcessors(processors, 'json'),
        css: reduceProcessors(processors, 'css')
    };
};


module.exports = getTokens;
