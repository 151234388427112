'use strict';

function Cache(storage) {
    this.storage = storage;
}

Cache.prototype.setItem = function (key, value, ttlSeconds) {
    if (!value) {
        return;
    }

    const data = {
        data: value
    };

    if(ttlSeconds) {
        var now = Date.now();
        var expiresAt = now + (ttlSeconds * 1000); // eslint-disable-line no-extra-parens
        data.expiresAt = expiresAt;
    }

    try {
        this.storage.setItem(key, JSON.stringify(data));
    } catch (e) {
        console.error('could not JSON.stringify value for cache:', value); //eslint-disable-line no-console
    }
};

Cache.prototype.getItem = function (key) {
    var item = this.storage.getItem(key);
    var cachedData;

    if (!item) {
        return null;
    }
    try {
        cachedData = JSON.parse(item);

        if (typeof cachedData.expiresAt !== 'undefined' && Date.now() > cachedData.expiresAt) {
            this.storage.removeItem(key);
            return null;
        }

        return cachedData.data;
    } catch (e) {
        console.error('could not parse cached item', item); //eslint-disable-line no-console
        return null;
    }
};

Cache.prototype.clear = function () {
    this.storage.clear();
};

exports.create = function (storage) {
    const storeDriver = storage ||
        (typeof window !== 'undefined' && typeof window.sessionStorage !== 'undefined' ?
            window.sessionStorage : require('localstorage-memory'));
    return new Cache(storeDriver);
};
