'use strict';

module.exports = () => ({
    key: 'type',
    description: 'Typography.',
    children: [
        {
            key: 'face',
            description: 'Typeface.',
            children: [
                {
                    key: 'base',
                    format: 'font-list',
                    description: 'Base typeface.',
                    value: ['Roboto', 'SF Pro Text', 'Helvetica Neue', 'Helvetica', 'sans-serif']
                }
            ]
        },
        {
            key: 'weight',
            description: 'Type weight.',
            children: [
                {
                    key: 'light',
                    description: 'Light type weight.',
                    value: 300
                },
                {
                    key: 'regular',
                    description: 'Regular type weight.',
                    value: 400
                },
                {
                    key: 'medium',
                    description: 'Medium type weight.',
                    value: 500
                },
                {
                    key: 'bold',
                    description: 'Bold type weight.',
                    value: 700
                }
            ]
        },
        {
            key: 'ratio',
            description: 'Represents the <font-size>:<line-height> ratio. To achieve a predictable and structured base-line grid, use line-heights that are a multiplier of the base measure, with font-size=(line-height * (ratio[0] / ratio[1])).',
            children: [
                {
                    key: 'base',
                    format: 'ratio',
                    description: 'Base ratio.',
                    value: [2, 3]
                },
                {
                    key: 'tight',
                    format: 'ratio',
                    description: 'Tight ratio.',
                    value: [5, 6]
                }
            ]
        },
        {
            key: 'tracking',
            description: 'Represents space between characters. This should be an em unit, as it is relative to the type size.',
            children: [
                {
                    key: 'tight-1',
                    format: 'em',
                    description: 'Tight tracking (1).',
                    value: -0.015
                },
                {
                    key: 'tight-2',
                    format: 'em',
                    description: 'Tight tracking (2).',
                    value: -0.03
                },
                {
                    key: 'loose-1',
                    format: 'em',
                    description: 'Loose tracking (1).',
                    value: 0.015
                },
                {
                    key: 'loose-2',
                    format: 'em',
                    description: 'Loose tracking (2).',
                    value: 0.03
                }
            ]
        }
    ]
});
