'use strict';

const defs = require('../defs');
const getTokens = require('./get-tokens');
const formatKey = require('./format-key');
const {hexToHsl} = require('./convert-color');
const getSurfaceSaturation = require('./get-surface-saturation');

const defaultProps = {
    // Zoom level
    zoom: 1,
    // Mode
    mode: 'dark',
    // Secondary color
    secondaryColor: '#239EDB',
    // Surface saturation
    surfaceSaturation: 'normal'
};

const normalizeColor = (value) => typeof value === 'string' ? hexToHsl(value) : value;

const resolveProps = (props) => {
    return [
        // Apply defaults
        (props) => Object.assign({}, defaultProps, props),

        // Normalize colors to HSL
        (props) => {
            const {primaryColor, secondaryColor, surfaceColor} = props;
            return Object.assign({}, props, {
                primaryColor: normalizeColor(primaryColor || secondaryColor),
                secondaryColor: normalizeColor(secondaryColor),
                surfaceColor: ['primaryColor', 'secondaryColor'].includes(surfaceColor) ? normalizeColor(props[surfaceColor]) : normalizeColor(surfaceColor)
            });
        },

        // Build/modify surfaceColor
        (props) => {
            const {surfaceColor} = props;
            const surfaceSaturationValue = getSurfaceSaturation(props);

            if (surfaceColor) {
                return Object.assign({}, props, {
                    surfaceColor: {
                        h: surfaceColor.h,
                        s: surfaceSaturationValue
                    }
                });
            }

            return Object.assign({}, props, {
                surfaceColor: {
                    h: props.primaryColor.h,
                    s: 0
                }
            });
        }
    ].reduce((props, processor) => processor(props), props);
};

/**
 * Extracts tokens with the mode path and hoists the keys to the root
 */
const extractModeTokens = (tokens, mode) => {
    const matchPath = ['mode', mode];
    const extract = (tokens, output) => tokens[output]
        .filter(({path}) => {
            return path
                .join('/')
                .includes(matchPath.join('/'));
        })
        .map((token) => {
            const {path} = token;
            const hoistedPath = path
                .join('/')
                .replace(matchPath.join('/'), '')
                .split('/')
                .filter((part) => part);

            return Object.assign({}, token, {
                key: formatKey(hoistedPath, output),
                path: hoistedPath
            });
        });

    return {
        json: extract(tokens, 'json'),
        css: extract(tokens, 'css')
    };
};

/**
 * Reduces collection to a keyed object
 */
const tokenCollectionToObject = (tokenCollection, verbose) => tokenCollection
    .reduce((output, token) => {
        const {key, value} = token;
        return Object.assign(output, {[key]: verbose ? token : value});
    }, {});

/**
 * Gets tokens from props
 */
const getTokensFromProps = (props = {}, verbose = false) => {
    const resolvedProps = resolveProps(props);
    const {mode} = resolvedProps;
    const tokens = getTokens(defs, resolvedProps);
    const modeTokens = extractModeTokens(tokens, mode);
    const mergedTokens = {
        json: [...tokens.json, ...modeTokens.json],
        css: [...tokens.css, ...modeTokens.css]
    };

    return {
        json: tokenCollectionToObject(mergedTokens.json, verbose),
        css: tokenCollectionToObject(mergedTokens.css, verbose)
    };
};

module.exports = getTokensFromProps;
