const getDesignTokens = require('@vizia/design-tokens');

const clientSideConfig = require('../conf/clientSideConfig');

const loadThemeProps = (theme) => new Promise((resolve) => {
    const {css: cssTokens} = getDesignTokens(theme);

    Object.entries(cssTokens).forEach(([key, value]) => {
        document.documentElement.style.setProperty(key, value);
    });

    window.requestAnimationFrame(resolve);
});

const loadLegacyTheme = (themeId) => new Promise((resolve, reject) => {
    const {themeRootUrl} = clientSideConfig();
    const url = new URL(`/${themeId}/theme.next.css`, themeRootUrl).href;
    const link = document.createElement('link');

    link.addEventListener('load', () => {
        window.requestAnimationFrame(resolve);
    }, {once: true});
    link.addEventListener('error', reject);

    link.href = url;
    link.type = 'text/css';
    link.rel = 'stylesheet';

    document.head.appendChild(link);
});

const loadTheme = (props) => {
    const {theme, themeId} = props;

    if (theme && Object.keys(theme).length) {
        return loadThemeProps(theme);
    } else if (themeId) {
        console.info('Loading legacy theme'); // eslint-disable-line no-console
        return loadLegacyTheme(themeId);
    }
    return Promise.resolve();
};

module.exports = loadTheme;
