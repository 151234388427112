

const uuidV4 = /^[a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12}$/i;

function isUuid(val) {
    return uuidV4.test(val);
}

exports.extractTokenIds = function extractTokenIds(config) {
    const tokenIds = config.scene.options.tokenIds;

    if (tokenIds) {
        return Object.assign({}, tokenIds);
    }

    const tokens = config.scene.options.tokens;

    if (!tokens) {
        return null;
    }

    if (Object.values(tokens).every(isUuid)) {
        return Object.assign({}, tokens);
    }

    return null;
};
