'use strict';

const recursiveFlatten = (memo, item) => {
    if (Array.isArray(item)) {
        return [
            ...memo,
            ...item.reduce(recursiveFlatten, [])
        ];
    }
    return [
        ...memo,
        item
    ];
};

const flatten = (memo, item) => [...memo, ...item];

module.exports = flatten;
module.exports.recursive = recursiveFlatten;
