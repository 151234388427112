'use strict';

const axios = require('axios');

module.exports = (code, clientId, redirectUrl, machineId) => {
    const url = 'https://graph.facebook.com/oauth/access_token';
    const params = {
        code,
        client_id: clientId,
        redirect_uri: redirectUrl,
        machine_id: machineId
    }

    return axios.get(url, {
        params
    }).then(res => {
        const {
            access_token,
            machine_id,
            expires_in
        } = res.data;

        return {
            accessToken: access_token,
            machineId: machine_id,
            expiresIn: expires_in
        };

    }).catch(err => {
        console.error(err);
        throw err;
    });
};
