

const loadingView = require('@vizia/view-loading');

class LoadingView {
    constructor(options) {
        if (!options || !(options.el instanceof window.HTMLElement)) {
            throw new Error('LoadingView requires an element.');
        }

        this.el = document.createElement('div');
        this.el.className = 'LoadingView fade-out';
        options.el.appendChild(this.el);

        this.fadeIn();
    }
    fadeIn() {
        this.el.innerHTML = loadingView();
        this.el.classList.remove('fade-out');
    }
    fadeOut() {
        this.el.classList.add('fade-out');
        setTimeout(() => {
            this.remove();
        }, 300);
    }
    remove() {
        this.el.innerHTML = '';
    }
}

module.exports = LoadingView;
