'use strict';

/**
 * @class
 * @classdesc A cross window/frame messaging system
 */
class MessagingBus {
    /**
     * @param {string} [handle=main] - a name to register this window under, this wil be used for message/request targeting, so all windows must register unique names
     */
    constructor(handle = window.name || 'main') {
        this._listener = this._listener.bind(this);
        this.destroy = this.destroy.bind(this);

        window.addEventListener('message', this._listener);
        window.addEventListener('beforeunload', this.destroy);

        this._localHandle = handle;
        this._callbacks = [];
        this._awaitingResponse = {};
        this._awaitingAcknowledgement = {};
        this._windowHandles = {};
        this._directDescendants = [];
        this._waitHandles = [];

        this._distributeHandle();
    }

    _distributeHandle() {
        this._iterateWindowFrames(window.top, w => {
            const payload = {
                isChild: w === window.parent,
            };

            this._sendToContentWindow(w, {
                action: MessagingBus.INTERNAL_ACTIONS.register,
                payload,
                from: this._localHandle
            });
            this._log('internal', 'REGISTRATION', payload);
        });

        const payload = {
            isChild: window.top === window.parent,
        };
        this._sendToContentWindow(window.top, {
            action: MessagingBus.INTERNAL_ACTIONS.register,
            payload,
            from: this._localHandle
        });
        this._log('internal','REGISTRATION', payload);
    }

    _iterateWindowFrames(w, iterator) {
        for(let i = 0; i < w.frames.length; i++) {
            const frame = w.frames[i];
            iterator(frame);
            this._iterateWindowFrames(frame, iterator);
        }
    }

    _listener(event) {
        const {
            _source,
            action,
            payload,
            from,
            to,
            isResponse = false,
            expectsResponse = false,
            isAck = false,
            ackCode,
            responseCode
        } = event.data;

        if(_source !== '__MessagingBus__') {
            //its not intended for us
            return;
        }

        if (Object.values(MessagingBus.INTERNAL_ACTIONS).includes(action)) {
            return this._internalActions(event);
        }

        if (to !== this._localHandle) {
            //its not ment for us
            return;
        }

        if (event.source !== this._windowHandles[from]) {
            return console.error('Message isnt from the expected window, blocking message...');
        }

        if (isAck) {
            const awaited = this._awaitingAcknowledgement[ackCode];
            awaited && awaited();
            return delete this._awaitingAcknowledgement[ackCode];
        }

        if (isResponse) {
            const awaited = this._awaitingResponse[responseCode];
            if (awaited && awaited.handle === from
                && `RESPONSE_${awaited.request}` === action) {
                delete this._awaitingResponse[responseCode];
                return awaited.deferred(payload);
            }
            return console.warn('a response came in that we weren\'t expecting', event.data);
        }

        const funcs = this._getCallbacks(action);

        if(expectsResponse) {
            if(funcs.length < 1) {
                return this._log('request', 'no callback setup for request:', action);
            }

            if(funcs.length !== 1) {
                return console.error('exactly 1 callback function must be assigned to a responder action');
            }

            const response = funcs[0](action, payload, from);

            if(!response) {
                return console.warn('callback for response didnt yeild a result');
            }

            this._log('request', 'RECIEVED', {
                from,
                action,
                responseCode
            });

            if(response.then) {
                return response.then(resp => {
                    return this._sendResponse(from, action, resp, responseCode);
                });
            }

            return this._sendResponse(from, action, response, responseCode);
        }

        this._log('send', 'RECIEVED', {
            from,
            action,
            ackCode,
            callbacks: funcs.length
        });

        if(ackCode) {
            this._sendAcknowledgement(from, ackCode);
        }

        funcs.forEach(func => {
            func(action, payload, from);
        });
    }

    _internalActions(event) {
        const {
            action,
            payload,
            from
        } = event.data;

        switch (action) {
            case MessagingBus.INTERNAL_ACTIONS.register:
                if (!this._windowHandles[from] || this._windowHandles[from] !== event.source) {
                    this._windowHandles[from] = event.source;
                    if(payload.isChild && !this._directDescendants.includes(from)) {
                        this._directDescendants.push(from);
                    }

                    this._sendToContentWindow(event.source, {
                        action: MessagingBus.INTERNAL_ACTIONS.register,
                        payload: {isChild: false},
                        from: this._localHandle
                    });

                    this._distributeDescendants(from);
                    this._log('internal','RECEIVE REGISTRATION', {from});
                }
                break;
            case MessagingBus.INTERNAL_ACTIONS.deregister:
                if (this._windowHandles[from]) {
                    delete this._windowHandles[from];

                    if (this._directDescendants.includes(from)) {
                        this._directDescendants
                            .splice(this._directDescendants.indexOf(from), 1);
                    }
                    this._log('internal', 'RECEIVE DEREGISTRATION', {from});
                }
                break;
            case MessagingBus.INTERNAL_ACTIONS.distribute:
                payload.windows.forEach(win => {
                    if(from !== this._localHandle) {
                        this._getContentWindowByHandle(from)
                            .then(contentWindow => {
                                this._sendToContentWindow(contentWindow.frames[win], {
                                    action: MessagingBus.INTERNAL_ACTIONS.register,
                                    payload: {isChild: false},
                                    from: this._localHandle
                                });
                            });
                    }
                });
                this._log('internal', 'RECEIVE DISTRIBUTE REQUEST', {from});
                break;
        }
    }

    _distributeDescendants(which) {
        const windows = this._directDescendants
            .filter(handle => {
                return handle !== which;
            })
            .map(handle => {
                const win = this._windowHandles[handle];
                const frameIndex = Array.from(window.frames).reduce((acc, frame, index) => {
                    if(frame === win) {
                        return index;
                    }
                    return acc;
                }, -1);

                return [handle, frameIndex];
            });


        Object.entries(this._getActiveHandles())
            .forEach(([frameHandle, contentWindow]) => {
                const filteredWindows = windows
                    .filter(([handle, frameIndex]) => frameHandle !== handle && frameIndex > -1)
                    .map(([,index]) => index);

                this._sendToContentWindow(contentWindow, {
                    action: MessagingBus.INTERNAL_ACTIONS.distribute,
                    payload: {
                        isDescendent: false,
                        windows: filteredWindows
                    },
                    from: this._localHandle
                });
            })
    }

    _getActiveHandles() {
        return Object.entries(this._windowHandles)
            .filter(([handle, win]) => win && win.self && handle !== this._localHandle)
            .reduce((acc, [handle, window]) =>
                Object.assign({}, acc, {[handle]: window}), {});
    }

    _sendResponse(handle, action, payload, responseCode) {
        return this._getContentWindowByHandle(handle)
            .then(contentWindow => {
                contentWindow.postMessage({
                    _source: '__MessagingBus__',
                    isResponse: true,
                    responseCode,
                    from: this._localHandle,
                    to: handle,
                    action: `RESPONSE_${action}`,
                    payload
                }, '*');
            });
    }

    _sendAcknowledgement(handle, ackCode) {
        this._getContentWindowByHandle(handle)
            .then(contentWindow => {
                contentWindow.postMessage({
                    _source: '__MessagingBus__',
                    isAck: true,
                    to: handle,
                    ackCode,
                    from: this._localHandle
                }, '*');
            });
    }

    _isInternalMessage(action) {
        return action.startsWith('__INTERNAL');
    }

    /**
     * Send Message to window with specific handle
     *
     * A Message is defined as a one shot command with no expected response
     *
     * @param {string} handle - Registered window handle
     * @param {string} action - Action string to pass with the payload
     * @param {Object} payload - the payload data to send with the message
     * @returns {Promise} you may await this promise as an assurance that the receiving end has acknowledged your message,
     * this only ensures that the message was received and not an assurance of being acted upon
     */
    sendMessage(handle, action, payload) {
        return this._getContentWindowByHandle(handle)
            .then(contentWindow => this.sendMessageToWindow(contentWindow, action, payload, handle));
    }

    /**
     * Send Message to a specific contentWindow
     *
     * A Message is defined as a one shot command with no expected response
     *
     * @param {WindowProxy} contentWindow - any valid content window in context
     * @param {string} action - Action string to pass with the payload
     * @param {Object} payload - the payload data to send with the message
     * @param {string} [windowHandle] - the handle of the window to which you are sending the message
     * @returns {Promise} you may await this promise as an assurance that the receiving end has acknowledged your message,
     * this only ensures that the message was received and not an assurance of being acted upon
     */
    sendMessageToWindow(contentWindow, action, payload, windowHandle) {
        const ackCode = this._getResponseCode();

        const ackPromise = new Promise((resolve) => {
            this._awaitingAcknowledgement[ackCode] = resolve;
        }).then(() => this._log(this._isInternalMessage(action) ? 'internal_send' : 'send', 'ACKNOWLEDGED', {
            to: handle,
            action,
            ackCode
        }));

        let handle = windowHandle;
        if(!handle) {
            try {
                handle = this._getHandleByContentWindow(contentWindow);
            } catch(e) {
                return Promise.reject();
            }
        }

        this._sendToContentWindow(contentWindow, {
            from: this._localHandle,
            ackCode,
            to: handle,
            action,
            payload
        });

        this._log(this._isInternalMessage(action) ? 'internal_send' : 'send', 'MESSAGE', {
            to: handle,
            action,
            payload,
            ackCode
        });

        return ackPromise;
    }

    /**
     * Send a Message to all other registered windows
     *
     * A Message is defined as a one shot command with no expected response
     *
     * @param {string} action - Action string to pass with the payload
     * @param {object} payload - the payload data to send with the message
     * @param {boolean} [directDescendantsOnly=false] - if enabled only sends message to direct descendants of the current window,
     * this will stop messages propagating to deeper frames or ancestors
     * @returns {Promise} you may await this promise as an assurance that the all receiving frames have acknowledged your message,
     * this only ensures that the message was received and not an assurance of being acted upon
     */
    sendMessageToAll(action, payload, directDescendantsOnly = false) {
        return Promise.all(Object.keys(this._getActiveHandles())
            .filter(handle => !directDescendantsOnly || this._directDescendants.includes(handle))
            .map(handle => {
                return this.sendMessage(handle, action, payload)
            }));
    }

    /**
     * Send a Message to all matching registered windows
     *
     * A Message is defined as a one shot command with no expected response
     *
     * @param {RegExp} pattern - regular expression for filtering window handles
     * @param {string} action - Action string to pass with the payload
     * @param {object} payload - the payload data to send with the message
     * @param {boolean} [directDescendantsOnly=false] - if enabled only sends message to direct descendants of the current window,
     * @returns {Promise} you may await this promise as an assurance that the all receiving frames have acknowledged your message,
     * this only ensures that the message was received and not an assurance of being acted upon
     */
    sendFilteredMessage(pattern, action, payload, directDescendantsOnly = false) {
        return Promise.all(Object.keys(this._getActiveHandles())
            .filter(handle => !directDescendantsOnly || this._directDescendants.includes(handle))
            .filter(handle => handle.match(pattern))
            .map(handle => {
                return this.sendMessage(handle, action, payload)
            }));
    }

    _sendToContentWindow(contentWindow, data) {
        if (contentWindow && typeof contentWindow.postMessage === 'function') {
            contentWindow.postMessage(Object.assign({_source: '__MessagingBus__'}, data), '*');
        }
    }

    _getResponseCode() {
        let code = Math.floor(Math.random() * 100000);

        while (Object.keys(this._awaitingResponse).includes(code) ||
        Object.keys(this._awaitingAcknowledgement).includes(code)) {
            code = Math.floor(Math.random() * 100000);
        }

        return code;
    };

    _getContentWindowByHandle(handle) {
        const contentWindow = this._getActiveHandles()[handle];

        if (!contentWindow) {
            // its possible we just need to wait for the handle to be registered
            return new Promise((resolve, reject) => {
                this._waitFor(() => {
                    return !!this._getActiveHandles()[handle];
                }, () => {
                    resolve(this._getActiveHandles()[handle]);
                }, () => {
                    reject(`no window found with handle: ${handle}`);
                }, 500);
            });
        }

        return Promise.resolve(contentWindow);
    }

    _getHandleByContentWindow(contentWindow) {
        const [handle] = Object.entries(this._getActiveHandles())
            .find(([, window]) => window === contentWindow);

        return handle;
    }

    _waitFor(waitFunction, onSuccess, onFail, timeout) {
        const waitHandle = Math.floor(Math.random() * 100000);
        this._waitHandles[waitHandle] = new Date().getTime();

        const interval = setInterval(() => {
            if(!this._waitHandles[waitHandle]) {
                clearInterval(interval);
            }

            if(waitFunction()) {
                delete this._waitHandles[waitHandle];
                clearInterval(interval);
                return onSuccess();
            }
            if(timeout < new Date().getTime() - this._waitHandles[waitHandle]) {
                delete this._waitHandles[waitHandle];
                clearInterval(interval);
                return onFail();
            }
        });

        return waitHandle;
    }

    _cancelWait(handle) {
        if(this._waitHandles[handle]) {
            delete this._waitHandles[handle];
        }
    }

    /**
     * Send Request to window with specific handle
     *
     * A Request is defined as a command expecting and awaiting a response, if no response is returned within the given timeout the promise will reject
     *
     * @param {string} handle - Registered window handle
     * @param {string} action - Action string to pass with the payload
     * @param {Object} payload - the payload data to send with the request
     * @param {number} [timeout=1000] - maximum time in milliseconds to await a response
     * @returns {Promise<RequestResponder>} The response from the specific window referenced by the Handle provided
     */
    sendRequest(handle, action, payload, timeout = 1000) {
        return this._getContentWindowByHandle(handle)
            .then(contentWindow => this.sendRequestToWindow(contentWindow, action, payload, handle, timeout));
    }

    /**
     * Send Request to specific contentWindow
     *
     * A Request is defined as a command expecting and awaiting a response, if no response is returned within the given timeout the promise will reject
     *
     * @param {WindowProxy} contentWindow - any valid content window in context
     * @param {string} action - Action string to pass with the payload
     * @param {Object} payload - the payload data to send with the request
     * @param {string} [windowHandle] - the handle of the window to which you are sending the message
     * @param {number} [timeout=1000] - maximum time in milliseconds to await a response
     * @returns {Promise<RequestResponder>} The response from the specific window referenced by the Handle provided
     */
    sendRequestToWindow(contentWindow, action, payload, windowHandle, timeout = 1000) {
        const responseCode = this._getResponseCode();

        let handle = windowHandle;
        if(!handle) {
            try {
                handle = this._getHandleByContentWindow(contentWindow);
            } catch(e) {
                return Promise.reject();
            }
        }

        const responsePromise = new Promise((resolve, reject) => {
            this._awaitingResponse[responseCode] = {
                handle,
                request: action,
                deferred: resolve
            };

            setTimeout(() => {
                reject('request timed out');
            }, timeout);
        }).then(d => {
            this._log('request', 'RESPONSE', {
                action,
                to: handle,
                responseCode
            });

            return d;
        });

        contentWindow.postMessage({
            _source: '__MessagingBus__',
            expectsResponse: true,
            responseCode,
            from: this._localHandle,
            to: handle,
            action,
            payload
        }, '*');

        this._log('request', 'MESSAGE', {
            to: handle,
            action,
            payload,
            responseCode
        });

        return responsePromise.then(response => ({response, handle}));
    }

    /** Send a Message to all other registered windows
     *
     * A Request is defined as a command expecting and awaiting a response, if no response is returned within the given timeout the promise will reject
     *
     * @param {string} action - Action string to pass with the payload
     * @param {Object} payload - the payload data to send with the request
     * @param {boolean} [directDescendantsOnly=false] - if enabled only sends message to direct descendants of the current window,
     * @param {boolean} [allowPartialResponse=false] - if enabled wont reject on a missing response within the timeout period unless all requests fail
     * @param {number} [timeout=1000] - maximum time in milliseconds to await a response
     * @returns {Promise<RequestResponder[]>} A List of responses from all registered windows
     */
    sendRequestToAll(action, payload, directDescendantsOnly = false, allowPartialResponse = false, timeout = 1000) {
        return new Promise(resolve => {
            if(Object.keys(this._getActiveHandles()).length < 1) {
                return setTimeout(() => {
                    resolve(this._getActiveHandles());
                }, 500);
            }
            return resolve(this._getActiveHandles());
        })
            .then(handles => this._sendToMultiple(Object.keys(handles)
                    .filter(handle => !directDescendantsOnly || this._directDescendants.includes(handle)),
                action, payload, allowPartialResponse, timeout));
    }

    /** Send a Message to all matching registered windows
     *
     * A Request is defined as a command expecting and awaiting a response, if no response is returned within the given timeout the promise will reject
     *
     * @param {RegExp} pattern - regular expression for filtering window handles
     * @param {string} action - Action string to pass with the payload
     * @param {Object} payload - the payload data to send with the request
     * @param {boolean} [directDescendantsOnly=false] - if enabled only sends message to direct descendants of the current window,
     * @param {boolean} [allowPartialResponse=false] - if enabled wont reject on a missing response within the timeout period unless all requests fail
     * @param {number} [timeout=1000] - maximum time in milliseconds to await a response
     * @returns {Promise<RequestResponder[]>} A List of responses from all registered windows
     */
    sendFilteredRequest(pattern, action, payload, directDescendantsOnly = false, allowPartialResponse = false, timeout = 1000) {
        return new Promise(resolve => {
            if(Object.keys(this._getActiveHandles()).length < 1) {
                return setTimeout(() => {
                    resolve(this._getActiveHandles());
                }, 500);
            }
            return resolve(this._getActiveHandles());
        })
            .then(handles => {
                const filtered = Object.keys(handles)
                    .filter(handle => !directDescendantsOnly || this._directDescendants.includes(handle))
                    .filter(handle => handle.match(pattern));

                return this._sendToMultiple(filtered, action, payload, allowPartialResponse, timeout);
            })
    }

    _sendToMultiple(handles, action, payload, allowPartialResponse = false, timeout = 1000) {
        return Promise.all(
            handles.map(handle => {
                const requestPromise = this.sendRequest(handle, action, payload, timeout);
                if(allowPartialResponse) {
                    return requestPromise
                        .catch(() => Promise.resolve({handle, error: 'timed out', timeout: true}));
                }
                return requestPromise;
            })
        ).then(results => {
            const resultsCount = results.filter(({response}) => !!response);
            if(resultsCount < 1) {
                throw new Error('None of the handlers responded within the timeout period');
            }
            return results;
        });
    }

    /**
     * Response Object definition, all responses will match this spec
     * @typedef RequestResponder
     * @type {Object}
     * @param {string} handle - the window handle the message is coming from
     * @param {*} response - the payload of the response message
     * @param {string} [error] - an error string if an error occurred
     * @param {boolean} [timeout] - true if the response timed out
     */

    /**
     *
     * A Callback function run when a message or request is dispatched, registered with [Add Callback Method]{@link MessagingBus#addCallback}
     *
     * @typedef Callback
     * @type {function}
     * @param {string} action - the action of the requested callback
     * @param {Object} payload - the data payload sent in the request
     * @param {string} handler - the handler that send the request
     * @returns {*} if used as a request handler this function must return a value that will be returned to the caller as the response
     */

    /**
     * Add a callback function to run when an action is dispatched
     * @param {string} action - Action string provided by the caller
     * @param {Callback} callback - Function called when message/request received
     */
    addCallback(action, callback) {
        this._callbacks.push({
            action,
            callback
        });
    }

    /**
     * Remove a callback function so that it will no longer be run upon an action being dispatched
     * @param {string} action - Action string provided by the caller
     * @param {Callback} callback - Function called when message/request received
     */
    removeCallback(action, callback) {
        const remove = this._callbacks
            .find(cb => {
                return action === cb.action && callback === cb.callback;
            });

        if (!remove) {
            return this._callbacks.splice(this._callbacks.indexOf(remove), 1);
        }
    }

    _getCallbacks(requestAction) {
        return this._callbacks
            .filter(({action}) => action === requestAction)
            .map(({callback}) => callback);
    }

    /**
     * deregister handle from all listeners
     */
    destroy() {
        this.sendMessageToAll(MessagingBus.INTERNAL_ACTIONS.deregister, {destroyed: true});
        window.removeEventListener('message', this._listener);
        window.removeEventListener('beforeunload', this.destroy);
    }

    /**
     * returns all currently registered handles for valid windows
     * @returns {string[]}
     */
    getActiveHandles() {
        return Object.keys(this._getActiveHandles());
    }

    /**
     * returns the handle string for the current frames parent
     * @return {string} handle of the parent frame
     * @throws Error if the parent window is not a registered instance
     */
    getParentHandle() {
        const [parentHandle] = Object.entries(this._getActiveHandles()).find(([key, value]) => {
            return value === window.parent;
        }) || [];

        if(!parentHandle) {
            throw new Error('Parent Window not registered');
        }

        return parentHandle;
    }

    _log(level, msg, data) {
        const debug = localStorage.getItem('messagingbus__debug');
        const logLevel = localStorage.getItem('messagingbus__debugLevel') || ['send', 'request'];
        let logColor = localStorage.getItem(`messagingbus__debugColor.${this._localHandle}`);

        if(!debug || !logLevel.includes(level)) {
            return;
        }

        if(!logColor) {
            logColor = `#${((1<<24)*Math.random()|0).toString(16)}`;
            localStorage.setItem(`messagingbus__debugColor.${this._localHandle}`, logColor);
        }

        if(debug === '*' || debug === this._localHandle ||
            (debug.startsWith('*') && this._localHandle.endsWith(debug.substr(1))) ||
            (debug.endsWith('*') && this._localHandle.startsWith(debug.substr(0, debug.length -1)))) {

            const output = typeof data === 'object' ? Object.entries(data).reduce((acc, [key, value]) => {
                return `${acc}\n${key}: ${['string', 'number'].includes(typeof value) ? value : JSON.stringify(value)}`
            }, '') : data;
            console.log(`%cMB ${this._localHandle} | ${level.toUpperCase()}:`, `color: ${logColor}`, msg, output);
        }
    }
}

MessagingBus.INTERNAL_ACTIONS = {
    register: '__INTERNAL_MESSAGING_REGISTRATION',
    deregister: '__INTERNAL_MESSAGING_DEREGISTRATION',
    distribute: '__INTERNAL_MESSAGING_DISTRIBUTION'
};

module.exports = MessagingBus;
