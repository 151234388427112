/*eslint no-prototype-builtins: "off"*/

const decodeJWT = require('jwt-decode');

function buildNullTokens(tokens, provider) {
    if (!tokens.hasOwnProperty(provider)) {
        tokens[provider] = null;
    }
    return tokens;
}

function extractTokens(token) {
    if (!token) {
        return {brandwatch: null};
    }
    return {
        vizia: token,
        brandwatch: decodeJWT(token).api2AccessToken
    };
}

function isBrandwatch(provider) {
    return provider === 'brandwatch';
}

function TokenExchanger(options) {
    this.authServiceRootUrl = options.authServiceRootUrl;
    this.bundleName = options.bundleName;
    this.getToken = options.getToken;
}

TokenExchanger.prototype.exchange = function (tokenArgs) {
    const tokenIds = tokenArgs || {};
    const providers = Object.keys(tokenIds);

    return this.getToken().then(extractTokens).then(tokens => {
        const onlyBrandwatch = providers.length === 0 || providers.every(isBrandwatch);

        const exchangedBwToken = {
            brandwatch: tokens.brandwatch,
            vizia: tokens.vizia
        };

        if (!tokens.brandwatch || onlyBrandwatch) {
            return providers.reduce(buildNullTokens, exchangedBwToken);
        }

        const providerUuidMap = Object.assign({}, tokenIds);

        // remove the special cases
        delete providerUuidMap.brandwatch;
        delete providerUuidMap.vizia;

        const url = new URL(`/v2/bundles/${this.bundleName}`, this.authServiceRootUrl);

        const options = {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify(providerUuidMap),
            headers: new Headers({
                Authorization: `Bearer ${tokens.vizia}`,
                'Content-Type': 'application/json'
            })
        };

        return fetch(url, options).then(res => {
            if (!res.ok) {
                console.warn('Bad response from auth service.');
                return providers.reduce(buildNullTokens, exchangedBwToken);
            }

            const contentType = res.headers.get('content-type') || '';

            if (!contentType.includes('application/json')) {
                console.warn('Non-JSON response from auth service.');
                return providers.reduce(buildNullTokens, exchangedBwToken);
            }

            return res.json().then(exchangedTokens => Object.assign(exchangedBwToken, exchangedTokens));
        }).catch(err => {
            console.warn(err);
            return providers.reduce(buildNullTokens, exchangedBwToken);
        });
    }).catch(err => {
        console.warn(err);
        return providers.reduce(buildNullTokens, {brandwatch: null});
    });
};

module.exports = TokenExchanger;
