'use strict';

const request = require('./lib/request');
const storageLayer = require('./lib/storageLayer')();
const tokenCache = require('./lib/tokenCache').create(storageLayer);

exports.getToken = (code, clientId, redirectUrl, tokenIdentifier) => {
    if(!tokenIdentifier){
        return Promise.reject(new Error('provide a tokenIdentifier'));
    }

    const storedToken = tokenCache.getToken(tokenIdentifier);

    if(storedToken){
        return Promise.resolve(storedToken);
    }

    const machineId = tokenCache.getMachineId(tokenIdentifier);

    return request(code, clientId, redirectUrl, machineId).then(data => {
        console.log(`old machineId: ${machineId}, new machineId: ${data.machineId}`);

        if(data.machineId){
            tokenCache.setMachineId(tokenIdentifier, data.machineId)
        }

        tokenCache.setToken(tokenIdentifier, data.accessToken, data.expiresIn);

        return data.accessToken;
    });
}
