

const axios = require('axios');

const commsErrors =  require('./communicationErrors');
const clientSideConfig =  require('../conf/clientSideConfig');

exports.fetch = function fetchBundle(url, idToken, slide) {
    if (!url) {
        return Promise.reject(new Error('No bundleUrl passed.'));
    }

    const axiosConfig = {
        timeout: clientSideConfig().requestTimeout * 1000,
        withCredentials: true
    };

    if (idToken) {
        axiosConfig.headers = {
            authorization: `Bearer ${idToken}`
        };
    }

    if (slide) {
        axiosConfig.params = {
            slide
        };
    }

    return axios.get(url, axiosConfig)
        .then(res => res.data)
        .catch(e => {
            if (e.code && e.code === 'ECONNABORTED') {
                return Promise.reject(commsErrors.createTimeoutError());
            }

            return Promise.reject(commsErrors.createRequestError());
        });
};
