

const TokenStore = require('donny-auth');
const queryString = require('query-string');
const getConfig = require('../conf/clientSideConfig');

let store = null;

function getStore(config, search) {
    if (search.otp) {
        return TokenStore.fromOTP(config.platformAuthStoreUrl, search.otp);
    }
    return Promise.resolve(new TokenStore(config.platformAuthStoreUrl));
}

module.exports = function getToken() {
    const search = queryString.parse(location.search);

    if (search.idToken && !search.otp) {
        return Promise.resolve(search.idToken);
    }

    const config = getConfig();

    if (!store) {
        store = getStore(config, search);
    }

    return store.then(store => store.getToken({aud: config.platformAuthAudience}));
};
