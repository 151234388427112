

// To do: finalise error code messages
// const messages = require('../lib/messages');

function showRefreshCountdown(el, time) {
    if (!time || !Number.isInteger(time)) {
        return;
    }

    let bar = el.querySelector('.bar');

    if (!bar) {
        bar = document.createElement('div');
        bar.className = 'bar';
        el.appendChild(bar);
    }

    el.classList.remove('animate');
    bar.style.transitionDuration = `${time}s`;

    requestAnimationFrame(() => {
        requestAnimationFrame(() => {
            el.classList.add('animate');
        });
    });
}

class MessageView {
    constructor(options) {
        if (!options || !(options.el instanceof window.HTMLElement)) {
            throw new Error('MessageView requires an element.');
        }

        this.el = document.createElement('div');
        this.el.className = 'MessageView';
        options.el.appendChild(this.el);
    }
    showMessage(message, type, refreshTime) {
        this.el.style.transitionDuration = '0s';
        this.el.classList.remove('show');
        this.el.textContent = message;

        requestAnimationFrame(() => {
            requestAnimationFrame(() => {
                this.el.style.transitionDuration = '0.3s';
                this.el.classList.add('show');
            });
        });

        showRefreshCountdown(this.el, refreshTime);
    }
    hideMessage() {
        this.el.classList.remove('show');
    }
    remove() {
        this.el.remove();
    }
}

module.exports = MessageView;
