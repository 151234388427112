

const config = {
    platformAuthAudience: CONFIG.platformAuthAudience,
    platformAuthStoreUrl: new URL(CONFIG.platformAuthStoreOrigin).href,
    authServiceRootUrl: new URL(CONFIG.authServiceOrigin).href,
    bundleRootUrl: new URL('/v1/bundle/', CONFIG.dtsServerOrigin).href,
    proxyRootUrl: new URL('/v1/proxy/', CONFIG.dtsServerOrigin).href,
    themeRootUrl: new URL(CONFIG.themeServerOrigin).href,
    requestTimeout: CONFIG.requestTimeout,
    chApiRootUrl: CONFIG.chApiRootUrl,
    viziaAssetsPath: new URL(CONFIG.viziaAssetsPath).href,
    qriouslyAPIUrl: CONFIG.qriouslyAPIUrl && new URL(CONFIG.qriouslyAPIUrl).href,
    mapboxToken: CONFIG.mapboxToken,
    geoServiceUrl: new URL(CONFIG.geoServiceUrl).href
};

module.exports = function getConfig() {
    return config;
};
