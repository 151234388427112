'use strict';

const constants = Object.freeze({
    /**
     * Browsers' default font scale is 16px, which all rem values derive from.
     * Axiom also sets this explicitly with font-size: 100%
     */
    CSS_ROOT_SCALE: 16,

    /**
     * All measures to derive from baseline grid unit
     */
    BASELINE_GRID_UNIT: 3
});

module.exports = constants;
