const START = 'bundle_start';
const DATA_LOAD = 'bundle_data_load';
const SCRIPTS_LOAD = 'bundle_scripts_load';

function* createExecutionPipeline(config, bundle, [loadBundle, renderBundle]) {
    const setup = config.then(c => bundle(c).then(b => [c, b]));
    let state = yield setup;

    if (state !== SCRIPTS_LOAD && state !== DATA_LOAD && state !== START) {
        return Promise.reject('Could not initialise pipeline'); // Immediately end pipeline if unrecognised state
    }
    // Each of these while statements function as a pause for each stage of the pipeline
    // If we hit a matching state, we stay in the loop until we get a state that is after the current step in the pipeline
    if (state === SCRIPTS_LOAD) {
        while (true) {
            state = yield setup;
            if (state === DATA_LOAD || state === START) {
                break;
            }
        }
    }

    const load = setup.then(loadBundle);
    if (state === DATA_LOAD) {
        while (true) {
            state = yield load;
            if (state === START) {
                break;
            }
        }
    }
    return load.then(b => renderBundle(b));
}

exports.createExecutionPipeline = createExecutionPipeline;
