

function promisify(variable) {
    return variable && typeof variable.then === 'function' ? variable : Promise.resolve(variable);
}

function wrapComputeConfig(bundle, tokenExchanger) {
    if (typeof bundle.computeConfig !== 'function') {
        return;
    }

    const originalCompute = bundle.computeConfig;

    bundle.computeConfig = function computeTokens(initialConfig, currentConfig) {
        return new Promise(((resolve, reject) => {
            const computedConfig = originalCompute.call(bundle, initialConfig, currentConfig);

            return promisify(computedConfig).then((config) => {
                const tokenIds = initialConfig.scene.options.tokenIds;

                return tokenExchanger.exchange(tokenIds || {}).then((tokens) => {
                    const updatedConfig = Object.assign({}, config, {
                        token: tokens.brandwatch,
                        vizia_access_token: tokens.vizia
                    });

                    updatedConfig.scene.options.tokens = Object.assign(
                        updatedConfig.scene.options.tokens || {},
                        tokens
                    );

                    return updatedConfig;
                });
            }).then(resolve, reject);
        }));
    };
}

exports.wrapComputeConfig = wrapComputeConfig;
