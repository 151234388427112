const MessagingBus = require('messagingbus');

module.exports = (id, onConfigUpdate, bundleFeatures) => {
    const messaging = new MessagingBus(`bundle-loader-${id}`);

    messaging.addCallback('BUNDLE_CONFIG_CHANGED', onConfigUpdate);
    setTimeout(() => {
        try {
            messaging.sendMessage(messaging.getParentHandle(), 'BUNDLE_FEATURES', bundleFeatures);
        } catch (e) {
            //probably not embeded somewhere where we need coms
        }
    }, 100);
};
