'use strict';

const keyFormatters = {
    /**
     * Camel-case key
     */
    json: (path) => path
        .reduce((memo, part) => [
            ...memo,
            ...part.split('-')
        ], [])
        .map((part, index) => {
            if (index === 0) {
                return part;
            }
            return `${part.slice(0, 1).toUpperCase()}${part.slice(1).toLowerCase()}`;
        })
        .join(''),
    /**
     * CSS custom property key
     */
    css: (path) => `--${path.join('-')}`
};

/**
 * Format key from a path
 */
const formatKey = (path = [], format = 'json') => keyFormatters[format](path);

module.exports = formatKey;
