

module.exports = function fetchScripts(scripts, opts = {}) {
    const {crossOrigin} = opts;
    const loadPromises = scripts.map(src => {
        return new Promise(((resolve, reject) => {
            const script = document.createElement('script');

            // https://errorception.com/docs/cors
            if (crossOrigin) {
                script.crossOrigin = crossOrigin;
            }

            script.src = src;

            let loaded = false;
            script.addEventListener('load', () => {
                loaded = true;
                resolve();
            });
            script.addEventListener('error', () => {
                let errorCause = 'load';
                if (loaded) {
                    errorCause = 'run';
                }

                const error = new Error(`could not ${errorCause} script: ${src}`);
                return reject(error);
            });

            document.body.appendChild(script);
        }));
    });

    return Promise.all(loadPromises);
};
