require('es6-promise').polyfill();
var RPC = require('frame-rpc');
var urlParse = require('url-parse');
module.exports = TokenStore;

function TokenStore(src) {
  var frame = document.createElement('iframe');
  frame.style.display = 'none';
  frame.src = src;
  document.body.appendChild(frame);

  var url = urlParse(src);
  var origin = url.protocol + '//' + url.host;

  var _this = this;

  this.loginUrl = origin + '/login/';

  this.loadFrame = new Promise(function (resolve, reject) {
    frame.addEventListener('load', function () {
      _this.rpc = RPC(window, frame.contentWindow, origin);
      setTimeout(function () { // give things a chance
        _this.rpc.call('ping', function () {
          resolve(_this.rpc);
        });
      }, 0);
      setTimeout(function () { // request has timed out
        reject(new Error('RPC Communication Error'));
      }, 2000);
    });
    frame.addEventListener('error', reject);
  });
}

function promisify(fn) {
  return new Promise(function(resolve, reject) {
    fn(function() {
      var args = Array.prototype.slice.call(arguments);
      // Newer versions of the iframe reply with a version (first param)
      if (args[0] === 'v2') {
        // and an error (second param)
        if (args[1]) {
          return reject.apply(null, args.slice(1));
        }
        // and any other params (rest)
        return resolve.apply(null, args.slice(2));
      }
      // Older versions of the iframe never pass an error, just give the args straight
      resolve.apply(null, args);
    });
  });
}

TokenStore.prototype.becomeUser = function getToken(opts) {
  return this.loadFrame.then(function (rpc) {
    return promisify(function (cb) {
      return rpc.call('becomeUser', opts, cb);
    });
  });
};

TokenStore.prototype.hasBecomeUser = function hasBecomeUser() {
  return this.loadFrame.then(function (rpc) {
    return promisify(function (cb) {
      return rpc.call('hasBecomeUser', cb);
    });
  });
};

TokenStore.prototype.getToken = function getToken(opts) {
  return this.loadFrame.then(function (rpc) {
    return promisify(function (cb) {
      return rpc.call('getToken', opts, cb);
    });
  });
};

TokenStore.prototype.removeToken = function removeToken(opts) {
  return this.loadFrame.then(function (rpc) {
    return promisify(function (cb) {
      return rpc.call('removeToken', opts, cb);
    });
  });
};

TokenStore.prototype.upgradeToken = function upgradeToken(opts) {
  return this.loadFrame.then(function (rpc) {
    return promisify(function (cb) {
      return rpc.call('upgradeToken', opts, cb);
    });
  });
};

TokenStore.prototype.getOTP = function getOTP(opts) {
  return this.loadFrame.then(function (rpc) {
    return promisify(function (cb) {
      return rpc.call('getOTP', opts, cb);
    });
  });
};

TokenStore.fromOTP = function fromOTP(src, otp) {
  const store = new TokenStore(src);

  store.removeToken = store.removeToken.bind(store, {session_id: otp});
  store._getToken = store.getToken;

  store.getToken = function (opts) {
    opts.session_id = otp;

    return store._getToken.call(store, opts);
  }

  delete store.becomeUser;
  delete store.hasBecomeUser;
  delete store.upgradeToken;
  delete store.getOTP;

  return store.loadFrame.then(function (rpc) {
    return promisify(function (cb) {
      return rpc.call('loginOTP', otp, cb);
    })
    .then(function () {
      return store;
    });
  });
};
