

const bundleFetcher = require('./bundleFetcher');

function fetchPromise(url, idToken, slide) {
    function handleErrorOnce(error) {
        if (!error.code || error.code < 500 || error.code > 599) {
            throw error;
        }

        return bundleFetcher.fetch(url, idToken, slide);
    }

    return bundleFetcher.fetch(url, idToken, slide).then(null, handleErrorOnce);
}

exports.fetch = fetchPromise;
