'use strict';

/**
 * Fine-tunes the amount of surface saturation, depending on incoming props
 */
const getSurfaceSaturation = (props) => {
    const {surfaceSaturation} = props;

    switch (surfaceSaturation) {
        case 'subtle':
            return 0.07;
        case 'normal':
            return 0.15;
        case 'vibrant':
            return 0.22;
        default:
            return 0;
    }
};

module.exports = getSurfaceSaturation;
