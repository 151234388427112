

const axios = require('axios');
const clientSideConfig = require('../conf/clientSideConfig');
const commsErrors =  require('./communicationErrors');

module.exports = (bundleName, jwt) => {
    const url = `${clientSideConfig().authServiceRootUrl}v2/bundles/${bundleName}/metadata`;
    return axios.get(url, {
        headers: {
            authorization: `Bearer ${jwt}`
        }
    })
        .then(res => res.data)
        .catch(() => Promise.reject(commsErrors.createRequestError()));
};
