'use strict';

module.exports = () => ({
    key: 'surface',
    alias: 'layer',
    description: 'Surfaces represent UI boxes that are \'lifted\' forwards in space.',
    children: [
        {
            key: 'radius',
            format: 'measure',
            description: 'Surface radius.',
            value: 5
        }
    ]
});
