'use strict';

/**
 * All color values are defined in HSL for easy readability and manipulation
 * Hue: 0 – 359
 * Saturation: 0 – 1
 * Lightness: 0 – 1
 */

const {getBlack, getWhite, getOnColor} = require('../lib/get-color');

module.exports = ({surfaceColor, primaryColor, secondaryColor}) => ({
    key: 'color',
    description: 'Colors, represented as HSL. Hue (0–359), Saturation (0–1), Lightness (0–1).',
    children: [
        {
            key: 'black',
            format: 'color',
            description: 'Black point.',
            value: getBlack(surfaceColor)
        },
        {
            key: 'white',
            format: 'color',
            description: 'White point.',
            value: getWhite(surfaceColor)
        },
        {
            key: 'positive',
            format: 'color',
            description: 'Positive sentiment, or positive trend.',
            value: {
                h: 130,
                s: 0.36,
                l: 0.46
            }
        },
        {
            key: 'on-positive',
            format: 'color',
            description: 'On positive sentiment.',
            value: getWhite(surfaceColor)
        },
        {
            key: 'neutral',
            format: 'color',
            description: 'Neutral sentiment, or neutral trend.',
            value: {
                h: 257,
                s: 0.05,
                l: 0.74
            }
        },
        {
            key: 'on-neutral',
            format: 'color',
            description: 'On neutral sentiment.',
            value: getBlack(surfaceColor)
        },
        {
            key: 'negative',
            format: 'color',
            description: 'Negative sentiment, or negative trend.',
            value: {
                h: 0,
                s: 0.64,
                l: 0.57
            }
        },
        {
            key: 'on-negative',
            format: 'color',
            description: 'On negative sentiment color.',
            value: getWhite(surfaceColor)
        },
        {
            key: 'success',
            format: 'color',
            description: 'Success, e.g. confirmation message.',
            value: {
                h: 167,
                s: 1,
                l: 0.37
            }
        },
        {
            key: 'on-success',
            format: 'color',
            description: 'On success color.',
            value: getWhite(surfaceColor)
        },
        {
            key: 'warning',
            format: 'color',
            description: 'Warning, e.g. warning message.',
            value: {
                h: 42,
                s: 0.67,
                l: 0.56
            }
        },
        {
            key: 'on-warning',
            format: 'color',
            description: 'On warning color.',
            value: getWhite(surfaceColor)
        },
        {
            key: 'failure',
            format: 'color',
            description: 'Failure, e.g. error message.',
            value: {
                h: 347,
                s: 0.97,
                l: 0.64
            }
        },
        {
            key: 'on-failure',
            format: 'color',
            description: 'On failure color.',
            value: getWhite(surfaceColor)
        },
        {
            key: 'primary',
            format: 'color',
            description: 'Primary color, e.g. slide header background.',
            value: primaryColor,
            children: [
                {
                    key: 'shadow',
                    format: 'color-shift',
                    description: 'Primary shadow color.',
                    value: {
                        l: -0.15
                    }
                }
            ]
        },
        {
            key: 'on-primary',
            format: 'color',
            description: 'On primary color, for content displayed on a primary surface.',
            value: getOnColor(primaryColor)
        },
        {
            key: 'secondary',
            alias: 'accent',
            format: 'color',
            description: 'Secondary color, e.g. hashtags, highlighted information.',
            value: secondaryColor,
            children: [
                {
                    key: 'shadow',
                    format: 'color-shift',
                    description: 'Secondary shadow color.',
                    value: {
                        l: -0.15
                    }
                }
            ]
        },
        {
            key: 'on-secondary',
            alias: 'on-accent',
            format: 'color',
            description: 'On secondary color, for content displayed on a secondary surface.',
            value: getOnColor(secondaryColor)
        },
        {
            key: 'shadow',
            format: 'color',
            description: 'Shadow color.',
            value: Object.assign({}, surfaceColor, {
                s: 0,
                l: 0
            })
        }
    ]
});
