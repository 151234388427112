'use strict';

const chroma = require('chroma-js');

const {hslToHex, hexToHsl} = require('./convert-color');

/**
 * Get black point
 */
const getBlack = (base) => Object.assign({}, base, {s: 0, l: 0.13});

/**
 * Get white point
 */
const getWhite = (base) => Object.assign({}, base, {s: 0, l: 1});

/**
 * Get on color from surface (can be any color used as a surface)
 * Returns either white, or a dark variant of the surface color
 */
const getPrimitiveOnColor = (surfaceColor) => {
    const DELTA_DELTA_THRESHOLD = 11;
    const darkPrimitive = Object.assign({}, surfaceColor, {s: 0.2, l: 0.22});
    const lightPrimitive = Object.assign({}, surfaceColor, {s: 0.2, l: 1});
    const darkDelta = chroma.deltaE(hslToHex(surfaceColor), hslToHex(darkPrimitive));
    const lightDelta = chroma.deltaE(hslToHex(surfaceColor), hslToHex(lightPrimitive));
    const deltaDelta = Math.abs(darkDelta - lightDelta);

    // Prefer white, unless the delta between white and black is less than an optical threshold
    if (lightDelta > darkDelta || (darkDelta >= lightDelta && deltaDelta < DELTA_DELTA_THRESHOLD)) {
        return lightPrimitive;
    }

    return darkPrimitive;
};

/**
 * Get optimised 'on' color from onColor ref, with enough contrast to surfaceColor
 */
const getOptimisedOnColor = (surfaceColor, onColor) => {
    const DELTA_THRESHOLD = 42;
    const surfaceChroma = chroma.hsl(surfaceColor.h, surfaceColor.s, surfaceColor.l);
    const refChroma = chroma.hsl(onColor.h, onColor.s, onColor.l);

    // If ref color already has enough contrast, use it

    if (chroma.deltaE(refChroma, surfaceChroma) > DELTA_THRESHOLD) {
        return onColor;
    }

    // Otherwise, we create a scale between a dark variant and white variant,
    // and walk it until we find enough contrast.
    // We use the lab color model to interpolate a smoother scale.
    // See https://vis4.net/chromajs/#chroma-scale for more info.

    const SCALE_SAMPLE_COUNT = 60;
    const scale = chroma
        .scale([
            refChroma.set('hsl.l', 0.2),
            refChroma,
            refChroma.set('hsl.l', 1)
        ])
        .domain([-1, 0, 1])
        .mode('lab');

    const hexSamples = scale.colors(SCALE_SAMPLE_COUNT);
    const darkerSamples = hexSamples.slice(0, Math.floor(SCALE_SAMPLE_COUNT / 2)).reverse();
    const lighterSamples = hexSamples.slice(Math.ceil(SCALE_SAMPLE_COUNT / 2), SCALE_SAMPLE_COUNT);

    const hexResult = [...darkerSamples, ...lighterSamples]
        .find((value) => chroma.deltaE(value, surfaceChroma) > DELTA_THRESHOLD);

    return hexResult ? hexToHsl(hexResult) : onColor;
};

/**
 * Get 'on' color, with a given surfaceColor (and optional onColor)
 */
const getOnColor = (surfaceColor, onColor) => {
    if (onColor) {
        return getOptimisedOnColor(surfaceColor, onColor);
    }
    return getPrimitiveOnColor(surfaceColor, onColor);
};

module.exports = {getBlack, getWhite, getOnColor};
