'use strict';

const {BASELINE_GRID_UNIT} = require('../lib/constants');

module.exports = () => ({
    key: 'measure',
    description: 'Measurements.',
    children: [
        {
            key: 'dot',
            format: 'measure',
            description: 'Equivalent of 1 pixel.',
            value: 1
        },
        {
            key: 'base',
            format: 'measure',
            description: 'Base grid unit to derive all measurements from.',
            value: BASELINE_GRID_UNIT
        },
        {
            key: 'gutter',
            format: 'measure',
            description: 'Grid gutter value. To be used for internal component and slide gutters.',
            value: BASELINE_GRID_UNIT * 8
        }
    ]
});
