'use strict';

const {getOnColor} = require('../lib/get-color');

module.exports = ({surfaceColor, primaryColor, secondaryColor}) => {
    const darkSurfaceColor = Object.assign({}, surfaceColor, {
        l: 0.15
    });
    const lightSurfaceColor = Object.assign({}, surfaceColor, {
        l: 1
    });

    return {
        key: 'mode',
        description: 'Mode variants.',
        children: [
            {
                key: 'dark',
                description: 'Dark mode. Always use dark mode on elements with a background image.',
                children: [
                    {
                        key: 'color',
                        children: [
                            {
                                key: 'background',
                                alias: 'frame',
                                format: 'color',
                                description: 'Background color. E.g. slide background.',
                                value: Object.assign({}, darkSurfaceColor, {
                                    l: 0.13
                                })
                            },
                            {
                                key: 'surface',
                                alias: 'bg',
                                format: 'color',
                                description: 'Surface color. E.g. component background.',
                                value: darkSurfaceColor,
                                children: [
                                    {
                                        key: 'shift-1',
                                        format: 'color-shift',
                                        description: 'Surface color shifted towards the on surface color by 1 step.',
                                        value: {
                                            l: 0.04
                                        }
                                    },
                                    {
                                        key: 'shift-2',
                                        format: 'color-shift',
                                        description: 'Surface color shifted towards the on surface color by 2 steps.',
                                        value: {
                                            l: 0.08
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'on-surface',
                                alias: 'base',
                                format: 'color',
                                description: 'On surface color. E.g. text / content.',
                                value: Object.assign({}, surfaceColor, {
                                    l: 0.95
                                }),
                                children: [
                                    {
                                        key: 'shift-1',
                                        format: 'color-shift',
                                        description: 'On surface color shifted towards the surface color by 1 step.',
                                        value: {
                                            l: -0.04
                                        }
                                    },
                                    {
                                        key: 'shift-2',
                                        format: 'color-shift',
                                        description: 'On surface color shifted towards the surface color by 2 steps.',
                                        value: {
                                            l: -0.08
                                        }
                                    },
                                    {
                                        key: 'em',
                                        alias: 'highlight',
                                        format: 'color-shift',
                                        description: 'On surface color, emphasised with higher contrast.',
                                        value: {
                                            l: 1
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'on-surface-primary',
                                format: 'color',
                                description: 'On surface primary color.',
                                value: getOnColor(darkSurfaceColor, primaryColor)
                            },
                            {
                                key: 'on-surface-secondary',
                                format: 'color',
                                description: 'On surface secondary color.',
                                value: getOnColor(darkSurfaceColor, secondaryColor)
                            }
                        ]
                    },
                    {
                        key: 'surface',
                        alias: 'layer',
                        children: [
                            {
                                key: 'shadow-opacity',
                                description: 'Shadow opacity of a surface.',
                                value: 0.1
                            }
                        ]
                    }
                ]
            },
            {
                key: 'light',
                description: 'Light mode.',
                children: [
                    {
                        key: 'color',
                        children: [
                            {
                                key: 'background',
                                alias: 'frame',
                                format: 'color',
                                description: 'Background color. E.g. slide background.',
                                value: Object.assign({}, lightSurfaceColor, {
                                    l: 0.99
                                })
                            },
                            {
                                key: 'surface',
                                alias: 'bg',
                                format: 'color',
                                description: 'Surface color. E.g. component background.',
                                value: lightSurfaceColor,
                                children: [
                                    {
                                        key: 'shift-1',
                                        format: 'color-shift',
                                        description: 'Surface color shifted towards the on surface color by 1 step.',
                                        value: {
                                            l: -0.04
                                        }
                                    },
                                    {
                                        key: 'shift-2',
                                        format: 'color-shift',
                                        description: 'Surface color shifted towards the on surface color by 2 steps.',
                                        value: {
                                            l: -0.08
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'on-surface',
                                alias: 'base',
                                format: 'color',
                                description: 'On surface color. E.g. text / content.',
                                value: Object.assign({}, surfaceColor, {
                                    l: 0.22
                                }),
                                children: [
                                    {
                                        key: 'shift-1',
                                        format: 'color-shift',
                                        description: 'On surface color shifted towards the surface color by 1 step.',
                                        value: {
                                            l: 0.04
                                        }
                                    },
                                    {
                                        key: 'shift-2',
                                        format: 'color-shift',
                                        description: 'On surface color shifted towards the surface color by 2 steps.',
                                        value: {
                                            l: 0.08
                                        }
                                    },
                                    {
                                        key: 'em',
                                        alias: 'highlight',
                                        format: 'color-shift',
                                        description: 'On surface color, emphasised with higher contrast.',
                                        value: {
                                            l: -1
                                        }
                                    }
                                ]
                            },
                            {
                                key: 'on-surface-primary',
                                format: 'color',
                                description: 'On surface primary color.',
                                value: getOnColor(lightSurfaceColor, primaryColor)
                            },
                            {
                                key: 'on-surface-secondary',
                                format: 'color',
                                description: 'On surface secondary color.',
                                value: getOnColor(lightSurfaceColor, secondaryColor)
                            }
                        ]
                    },
                    {
                        key: 'surface',
                        alias: 'layer',
                        children: [
                            {
                                key: 'shadow-opacity',
                                description: 'Shadow opacity of a surface.',
                                value: 0.04
                            }
                        ]
                    }
                ]
            }
        ]
    };
};
