'use strict';

module.exports = {
    key: 'viz',
    children: [
        require('./measure'),
        require('./type'),
        require('./layer'),
        require('./color'),
        require('./mode')
    ]
};
